import { CircularProgress, Container } from '@mui/material';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { invoiceApi, InvoiceResponse } from '../api/invoice';
import { Error } from '../components/Error/Error';
import { Invoice } from '../models/Invoice';
import './styles.css';

const InvoiceContext = createContext<Invoice>(null!);

export const useInvoiceContext = () => useContext(InvoiceContext);

interface Props {
  children?: any;
}
export const InvoiceContextProvider = ({ children }: Props) => {
  const [invoice, setInvoice] = useState<Invoice>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const hasError = useRef(false);

  useEffect(() => {
    const invoiceId = window.location.pathname.replace('/', '');
    setIsLoading(true);
    invoiceApi.get(`/invoice/${invoiceId || 1}`).then(({ data }: { data: InvoiceResponse }) => {
      data.success && data.data && setInvoice(data.data);
      hasError.current = !data.success || !data.data;
      setIsLoading(false);
    });
  }, []);

  return (
    <InvoiceContext.Provider value={invoice}>
      <Container
        disableGutters
        maxWidth='xs'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!isLoading && children}
        {isLoading && <CircularProgress color='secondary' />}
        {!isLoading && hasError.current && <Error />}
      </Container>
    </InvoiceContext.Provider>
  );
};
